import styled from "styled-components"
import { Col, Button, Dropdown } from "react-bootstrap"
import NumberFormat from "react-number-format"
import { CustomButton } from "../Buttons/parent"
import IconCheckGreen from "../../images/icon-check-green.svg"

export const CalculatorDiv = styled.div`
  width: 410px;
  max-width: 519px;
  height: auto;
  padding: 26px 40px;
  background-color: white;
  box-shadow: 0px 7px 15px rgba(15, 44, 87, 0.15);
  border-radius: 15px;
  position: relative;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const Ornament = styled.div`
  position: absolute;
  top: 70px;
  left: 40px;
`

export const VerticalRow = styled.div`
  width: 1px;
  border-left: 1px solid #6c6365;
  position: relative;
  border-left-color: #c8c8c5;
  height: 91px;

  :before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: white;
    border: 1px solid #6c6365;
    position: absolute;
    border-radius: 4px;
    left: -5px;
    top: 0;
  }
  :after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: white;
    border: 1px solid #6c6365;
    position: absolute;
    border-radius: 4px;
    left: -5px;
    bottom: 0;
  }
`

export const SendLabel = styled.label`
  font-family: "GothamBook";
  color: #6c6365;
  font-size: 12px;
  position: absolute;
  z-index: 4;
  top: 5px;
  left: 12px;
`

export const SendInputGroup = styled.div`
  flex: 4;
  gap: 10px;
  height: 55px;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: strech;
`

export const CountryFlag = styled.img`
  /* object-fit: cover; */
  margin-left: 8px;
  margin-right: 5px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  border: 0.5px solid lightgray;
`

export const CurrencyAmount = styled.input`
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  background-color: #fafafa;
  color: #db64a0;
  font-family: "GothamMedium";
  height: 100%;
  text-align: left;
  border: none;
  border-radius: 10px;
  text-align: right;
  :focus {
    outline: none;
    background-color: #f6f6f6;
  }
`

export const CurrencyInputRow = styled.div`
  display: flex;
`

export const CurrencyInputWrapper = styled.div``

export const CurrencyInputLabel = styled.label`
  font-family: "GothamMedium";
  font-weight: 500;
  color: #333334;
  line-height: 22px;
  font-size: 14px;
`

export const InputGroupAmount = styled.div`
  flex: 1.7;
  text-align: left;
  position: relative;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 10px;

  button.dropdown-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span,
    img,
    svg {
      flex: 1;
    }
    &::after {
      content: none;
    }
  }
`

export const DetailsText = styled.div`
  color: #6c6c71;
  display: flex;
  flex-direction: row;
  font-family: "GothamBook";
  font-size: 12px;
  letter-spacing: 0.1px;
  padding: 18px 0px;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
`

export const SellingPointList = styled.ul`
  list-style-image: url(${IconCheckGreen});
  padding-left: 25px;
  li {
    margin-bottom: 5px;
    font-family: "GothamBook";
    padding-left: 15px;
    font-size: 14px;

    strong {
      font-family: "GothamBold";
    }
  }
`

export const DetailsDiv = styled.div`
  margin-left: 30px;
  font-size: 14px;
  margin-bottom: 1rem;
`

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
`

export const DetailsImg = styled.img`
  margin-top: 2px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 33px;
`

export const DetailsSpan = styled.span`
  font-family: "GothamBook";
  color: #6c6365;
  flex: 20%;
  position: relative;
  :after {
    content: ":";
    position: absolute;
    right: 0;
  }
`

export const DetailSpanValue = styled.span`
  color: #48a9b5;
  text-align: left;
  font-family: "GothamMedium";
  position: relative;
  width: 255px;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const SavingValueDiv = styled.div`
  color: #6c6365;
  font-size: 12px;
  background-color: #e6e6e6;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
`

export const SavingValueText = styled.div`
  text-align: center;
  width: 100%;
`
export const SavingValueP = styled.p`
  margin-bottom: 0;
`

export const SavingValueSpan = styled.span`
  font-family: "GothamMedium";
  color: #196e97;
`

export const SavingValueSmall = styled.small`
  font-family: "GothamBook";
  color: #48a9b5;
  font-size: 80%;
  font-weight: 400;
`

export const WillAriveP = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: ${p => p.mbottom};
`

export const WillAriveSpan = styled.span`
  color: #48a9b5;
`

export const ButtonDiv = styled.div`
  text-align: center;
`

export const CalcButton = styled(Button)`
  font-family: "GothamMedium";
  background-color: #48a9b5;
  border-color: #48a9b5;
  color: #ffd27c;
  text-transform: uppercase;
  :hover,
  :active,
  :focus {
    background-color: #48a9b5;
    border-color: #48a9b5;
    color: #ffc107;
  }
`

export const CustomDropdownMenu = styled(Dropdown.Menu)`
  height: auto !important;
  max-height: 250px;
  overflow: auto !important;
  min-width: 300px;
`

export const CustomDropdownItem = styled(Dropdown.Item)`
  font-family: "GothamBook";
  :hover {
    background-color: #d888b0;
    color: white;
  }
`

export const SearchBox = styled.input`
  min-width: 300px;
  padding: 5px 10px;
  margin: 0px 15px 5px 15px;
  font-family: "GothamBook";
  outline: none;
  border: solid 1px #d6d6d6;
  border-radius: 5px;
`
export const SendButton = styled(CustomButton)`
  display: block;
  width: 100% !important;
  padding-top: 17px;
  padding-bottom: 17px;
  letter-spacing: 0.5px;
  line-height: 24px;
`

export const TotalPaymentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    color: #333334;
    font-family: "GothamBook";
    font-size: 14px;
    /* margin-right: 60px; */
  }
  h6 {
    margin-bottom: 0px;
    color: #2c4a77;
    font-size: 16px;
    font-family: "GothamMedium";
  }
`
