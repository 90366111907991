import React from "react"
import { LegalDownload } from "../HeaderComponents/HeaderStyle"
import AppStore from "../../images/image-app-store.svg"
import PlayStore from "../../images/image-play-store.svg"

export function StoreLinks({ style, className }) {
  const hrefAppstore = `https://transfez.onelink.me/e9Ud/web1a?page=send`
  const hrefPlaystore = `https://transfez.onelink.me/e9Ud/web1b?page=send`

  return (
    <div style={style} className={className}>
      <div className="d-flex align-items-center justify-content-center">
        <a href={hrefAppstore} target="_blank">
          <LegalDownload src={AppStore} alt="Appstore" />
        </a>
        <a href={hrefPlaystore} target="_blank">
          <LegalDownload src={PlayStore} alt="Playstore" />
        </a>
      </div>
    </div>
  )
}
