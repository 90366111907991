import axios from "axios"
import { useEffect, useState } from "react"

const createObject = (baseURL, timeout = 30000) => ({
  baseURL,
  timeout,
  CancelToken: axios.CancelToken,
})
export const ROOT_URL =
  process.env.GATSBY_ROOT_URL_ENV || "https://sawtooth.rubyh.co/"

//production things => api.production.transfez.io
export const ROOT_URL_IO =
  process.env.GATSBY_ROOT_IO_URL_ENV || "https://sawtooth.rubyh.co/"
// "https://sawtooth.rubyh.co/"
// production things => api.production.transfez.io

export const SGD_ROOT_URL = process.env.GATSBY_SGD_ROOT_URL_ENV
export const STRAPI_URL = "https://strapi.transfez.com"

export const api = axios.create(createObject(ROOT_URL))
export const apiIO = axios.create(createObject(ROOT_URL_IO))
export const apiSG = axios.create(createObject(SGD_ROOT_URL))
export const apiStrapi = axios.create(createObject(STRAPI_URL))

const apiTypeDecider = type => {
  if (type == "sg") return apiSG
  if (type == "io") return apiIO
  if (type == "strapi") return apiStrapi
  return api
}

export const fetch = ({
  url,
  formatter = val => val,
  type,
  defaultValue = [],
  woInit,
}) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(defaultValue)

  const getData = async () => {
    try {
      setLoading(true)
      const getApi = apiTypeDecider(type)
      const { data } = await getApi.get(url)
      const result = formatter(data)
      setData(result)
    } catch (err) {
      console.log("err:", err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (woInit) return
    getData()
  }, [])

  return { data, loading, refetch: getData }
}

export const fetchSG = ({
  url,
  formatter = val => val,
  defaultValue = [],
  woInit,
}) => {
  const fetchProps = fetch({ url, type: "sg", woInit, defaultValue, formatter })
  return fetchProps
}
