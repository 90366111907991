import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import { get } from "lodash"
import { noCase } from "no-case"
import numeral from "numeral"
import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import iconDevide from "../../images/icon-devide.png"
import iconMinus from "../../images/icon-minus.png"
import { api, apiSG, apiStrapi } from "../../tools/api"
import {
  currToNumber,
  formatCurrency,
  thousands_separators,
  useDebounce,
} from "../../tools/tools"
import { CalculatorJson } from "../location"
import {
  ButtonDiv,
  CalculatorDiv,
  CountryFlag,
  CurrencyAmount,
  CurrencyInputLabel,
  CurrencyInputRow,
  CurrencyInputWrapper,
  CustomDropdownItem,
  CustomDropdownMenu,
  DetailsDiv,
  DetailsImg,
  DetailSpanValue,
  DetailsRow,
  DetailsSpan,
  DetailsText,
  InputGroupAmount,
  Ornament,
  SavingValueDiv,
  SavingValueP,
  SavingValueSmall,
  SavingValueSpan,
  SavingValueText,
  SearchBox,
  SellingPointList,
  SendButton,
  SendInputGroup,
  SendLabel,
  VerticalRow,
  WillAriveP,
  WillAriveSpan,
  TotalPaymentDiv,
} from "./CalculatorStyle"
import "./style.css"
import SendIcon from "../../images/send-icon.svg"
import rrs from "react-string-replace"
import { OutlineButton } from "../Buttons/parent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const DropDownWrapper = ({ iso, currency, children }) => (
  <InputGroupAmount>
    <Dropdown>
      <Dropdown.Toggle
        // id="dropdown-sender-basic"
        className="dropdown-toggle"
        style={{ fontFamily: "GothamBook" }}
      >
        <span>{currency}</span>
        <CountryFlag
          src={`https://flagcdn.com/h80/${noCase(iso || "")}.png`}
          alt="flag"
        />
        <FontAwesomeIcon
          icon={faChevronDown}
          color="#DA649F"
          className="pl-1"
        />
      </Dropdown.Toggle>
      {children}
    </Dropdown>
  </InputGroupAmount>
)

const requestFormatter = ({
  amountSender,
  amountReceipt,
  recipient,
  sender,
  isSource,
}) => {
  const { iso_3: sIso3, currency: sCurrency } = sender || {}
  const { iso_3: rIso3, currency: rCurrency } = recipient || {}

  const sourceAmount = isSource ? { amount: Number(amountSender) || 0 } : {}
  const receiptAmount = !isSource ? { amount: Number(amountReceipt) || 0 } : {}

  const source = {
    country: sIso3,
    currency: sCurrency,
    ...sourceAmount,
  }

  const destination = { country: rIso3, currency: rCurrency, ...receiptAmount }
  const request = {
    user_id: 2,
    transaction_method_name: "all_banks_idn",
    mode: isSource ? "source_amount" : "destination_amount",
    source,
    destination,
  }
  return request
}

const sgHooks = () => {
  const location = useLocation()
  const isSG = location.pathname.includes("/sg")

  const initFetchSG = async ({
    setListData,
    setDisplayedContacts,
    setRecipient,
    setDefaultFlag,
    currentIso,
  }) => {
    try {
      const { data } = await apiSG.get("/api/countries")
      const arrData = get(data, "data", [])
      const recipientObject = arrData
        .filter(
          ({ iso_3, currency }) => iso_3 == currentIso || currency == currentIso
        )
        .pop()
      const iso = get(recipientObject, "iso", "").toLowerCase()
      const filteredData = arrData.filter(({ iso_3 }) => iso_3 !== "SGP")
      setListData(filteredData)
      setDisplayedContacts(filteredData)
      setRecipient(recipientObject)
      setDefaultFlag(iso)
    } catch (error) {
      console.log("error:", error)
    }
  }

  const changeSenderValuesSG = async props => {
    try {
      const {
        setAmountReceipt,
        setSaving,
        setAmountSender,
        setToggle,
        amountReceipt,
      } = props
      setAmountReceipt(formatCurrency(amountReceipt))
      const request = requestFormatter({ ...props, isSource: false })
      const url = "/api/calculator"
      const { data } = await apiSG.post(url, request)
      const { total_save_up, source_amount, transaction_method } = get(
        data,
        "data",
        {}
      )
      setSaving(thousands_separators(Math.round(total_save_up)))

      const amountSender =
        Number(source_amount) < 0
          ? 0
          : numeral(Math.round(source_amount)).format("0,0")

      setAmountSender(amountSender)
      setToggle(2)
    } catch (error) {
      console.log("error:", error)
    }
  }

  const changeRecipientValuesSG = async props => {
    try {
      const {
        setAmountSender,
        amountSender,
        setSaving,
        setCost,
        setKurs,
        setAmountReceipt,
        setToggle,
        setSgSLA,
      } = props
      setAmountSender(numeral(amountSender).format("0,0"))
      const request = requestFormatter({ ...props, isSource: true })
      const url = "/api/calculator"
      const { data } = await apiSG.post(url, request)
      const {
        total_save_up,
        transfer_fee,
        destination_amount,
        rate,
        transaction_method,
      } = get(data, "data", {})
      const safeSLA = get(transaction_method, "sla_multilanguage", {})
      setSgSLA(safeSLA)
      setSaving(thousands_separators(Math.round(total_save_up)))
      setCost(numeral(transfer_fee).format("0,0"))
      setKurs(numeral(rate).format("0,0.00"))

      const amountReceipt =
        Number(destination_amount) < 0 ? 0 : formatCurrency(destination_amount)

      setAmountReceipt(amountReceipt)
      setToggle(1)
    } catch (error) {
      console.log("error:", error)
    }
  }

  return { initFetchSG, changeSenderValuesSG, changeRecipientValuesSG, isSG }
}

const SortedArrayofObject = array => {
  return array.sort(function (a, b) {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  })
}

const formatterInit = ({
  apiData,
  strapiCountriesRaw,
  currentRecipientIso,
  isSendMoney,
}) => {
  const countriesApiData = get(apiData, "countries", [])
  const strapiCountries = strapiCountriesRaw || []

  const filteredStrapiCountries = strapiCountries.filter(
    ({ Active, Available }) => Active && Available
  )
  const strapiIso3 = filteredStrapiCountries.map(({ iso_3 }) => iso_3)
  const filteredList = countriesApiData.filter(({ iso_3 }) =>
    strapiIso3.includes(iso_3)
  )
  const list = filteredList.map(({ sla_multilanguage, ...rest }) => ({
    ...rest,
    sla_multilanguage,
  }))
  const recipient = list
    .filter(
      ({ iso_3, currency }) =>
        iso_3 == currentRecipientIso || currency == currentRecipientIso
    )
    .pop()
  const recipient_iso = get(recipient, "iso", "").toLowerCase()

  // ---------- default get list 0 ------------- //
  // const firstList = list[0] || {}
  // ---------- default get list 0 ------------- //

  const firstList = list?.filter(item => item.iso === "TH")[0] || {}

  const firstListIso = (firstList?.iso || "").toLowerCase()
  const isoFlag = isSendMoney ? recipient_iso : firstListIso
  const listData = SortedArrayofObject(list)
  const currentRecipient = isSendMoney ? recipient : firstList

  return { list, recipient, listData, currentRecipient, isoFlag }
}

const defaultRecipientSG = {
  currency: "IDR",
  id: 95,
  iso: "id",
  iso_3: "IDN",
  iso_name: "Indonesia",
  sla_multilanguage: {
    id: "tomorrow",
    en: "besok hari",
  },
}
const defaultRecipientIndo = {
  currency: "AUD",
  id: 14,
  iso: "au",
  iso_3: "AUS",
  name: "Australia",
  sla_multilanguage: {
    id: "H+1 hari kerja jika dikirim sebelum jam 11:30 (GMT+7)",
    en: "D+1 banking day if send before 11:30 AM (GMT+7) ",
  },
}
const defaultSenderSG = {
  currency: "SGD",
  id: 184,
  iso: "sg",
  iso_3: "SGP",
  name: "Singapore",
}
const defaultSenderIndo = {
  currency: "IDR",
  id: 95,
  iso: "id",
  iso_3: "IDN",
  name: "Indonesia",
}
function Calculator({ propsLink, style, className }) {
  const location = useLocation()
  const {
    initFetchSG,
    changeSenderValuesSG,
    changeRecipientValuesSG,
    isSG,
  } = sgHooks()
  const isEnglish = location.pathname.includes("/en")
  const isSendMoney = location.pathname.includes("send-money")
  const CalculatorContent = CalculatorJson()
  const {
    title1,
    title2,
    title3,
    title4,
    title5,
    title6,
    title7,
    title8,
    sellingPoint,
    today,
  } = CalculatorContent
  const [listData, setListData] = useState([])
  const [toggle, setToggle] = useState(1)
  const [cost, setCost] = useState()
  const [kurs, setKurs] = useState()
  const [saving, setSaving] = useState()
  // const [estimate, setEstimate] = useState()
  const [amountSender, setAmountSender] = useState(isSG ? "200" : "20000000")
  const [amountReceipt, setAmountReceipt] = useState("")
  const [displayedContacts, setDisplayedContacts] = useState([])
  const [defaultFlag, setDefaultFlag] = useState("au")
  const [defaultCurr, setDefaultCurr] = useState(isSG ? "SGD" : "IDR")
  const [sgSLA, setSgSLA] = useState({ id: "besok", en: "tomorrow" })
  const [limitTransaction, setLimitTransaction] = useState(0)

  const recipientDefVal = isSG ? defaultRecipientSG : defaultRecipientIndo

  const [recipient, setRecipient] = useState(recipientDefVal)

  const senderDefVal = isSG ? defaultSenderSG : defaultSenderIndo

  const [sender, setSender] = useState(senderDefVal)

  const isSGISO = sender?.iso == "sg"

  const isChina = recipient?.iso == "CN"
  const isChinaQuery = isChina
    ? "&delivery_method_type=mobilewallet&delivery_method_id=1"
    : ""
  // const isChinaQuery = isChina ? "" : ""

  const [isRecipientFilled, setIsRecipientFilled] = useState(false)

  const allValueHook = {
    listData,
    toggle,
    cost,
    kurs,
    saving,
    amountSender,
    amountReceipt,
    displayedContacts,
    defaultFlag,
    recipient,
    sender,
    sgSLA,
    limitTransaction,
  }

  const allSetHook = {
    setListData,
    setToggle,
    setCost,
    setKurs,
    setSaving,
    setAmountSender,
    setAmountReceipt,
    setDisplayedContacts,
    setDefaultFlag,
    setRecipient,
    setSender,
    setSgSLA,
    setLimitTransaction,
  }

  const allHooks = {
    ...allValueHook,
    ...allSetHook,
  }

  const sourceCountries = [
    {
      currency: "IDR",
      id: 95,
      iso: "id",
      iso_3: "IDN",
      name: "Indonesia",
    },
    // {
    //   currency: "SGD",
    //   id: 184,
    //   iso: "sg",
    //   iso_3: "SGP",
    //   name: "Singapore",
    // },
  ]

  const handleChangeCountry = (iso_3, id, isRecipient) => {
    const isSource = !isRecipient
    const isIso3Sgp = iso_3 == "SGP"
    if (isSource) {
      const source = sourceCountries
        .filter(({ id: idCountry }) => idCountry == id)
        .pop()
      setSender(source)
      const currency = get(source, "currency", "")
      const senderAmount = isIso3Sgp ? "200" : "20000000"
      setRecipient(isSGISO ? defaultRecipientSG : defaultRecipientIndo)
      setDefaultCurr(currency)
      setAmountSender(numeral(senderAmount).format("0,0"))
      return
    }

    const recipient = listData
      .filter(({ id: idCountry }) => idCountry == id)
      .pop()
    setRecipient(recipient)
    setDefaultFlag(recipient?.iso)
  }

  const SafeRecipientCurrency = get(recipient, "currency", "") || ""
  const SafeRecipientEn = isChina
    ? "Today"
    : get(recipient, "sla_multilanguage.en", "") || ""
  const SafeRecipientId = isChina
    ? "Hari ini"
    : get(recipient, "sla_multilanguage.id", "") || ""
  const SafeIdRecipient = get(recipient, "id", "") || 14
  const SafeRecipientSLA = isEnglish ? SafeRecipientEn : SafeRecipientId

  const handleSearch = event => {
    const search = (event?.target?.value || "").toLowerCase()
    const filteredArray = listData.filter(item => {
      const { name, iso_name, iso_3, currency } = item || {}
      const searchQuery = `${name} ${iso_name} ${iso_3} ${currency}`
      const fullSearch = searchQuery.toLowerCase()
      const result = fullSearch.includes(search)
      return result
    })
    setDisplayedContacts(filteredArray)
  }

  useEffect(() => {
    if (toggle === 1) {
      changeRecipientValues(true)
    }
    if (toggle === 2) {
      changeRecipientValues(true)
    }
  }, [recipient])

  const getIso =
    typeof window !== "undefined" && isSG
      ? (typeof window !== "undefined" && localStorage.getItem("iso_3")) ||
        "IDR"
      : (typeof window !== "undefined" && localStorage.getItem("iso_3")) ||
        "AUD"

  // this function called as initial fetch
  // for fetching countries and inits recipient value
  const fetchData = async () => {
    try {
      const url = "/api/conversion_rates/list_countries"
      const { data: apiData } = await api.get(url)
      const strapiUrl = "/list-of-countries"
      const { data } = await apiStrapi.get(strapiUrl)
      const { listData, currentRecipient, isoFlag } = formatterInit({
        apiData,
        strapiCountriesRaw: data,
        currentRecipientIso: getIso,
        isSendMoney,
      })
      setListData(listData)
      setDisplayedContacts(listData)
      setRecipient(currentRecipient)
      setDefaultFlag(isoFlag)
    } catch (error) {
      console.log("error:", error)
    }
  }
  // this function called as initial fetch

  // initial fetch
  useEffect(() => {
    if (isSGISO) return
    fetchData()
  }, [getIso, isSGISO])

  useEffect(() => {
    if (isSGISO) {
      initFetchSG({ ...allSetHook, currentIso: "IDR" })
      return
    }
  }, [isSGISO])
  // initial fetch

  // calls these functions when user types on calculator
  // These are used for sets currencies amounts
  const onChange = e => {
    const { isRecipient } = e
    const getText = e.target.value
    if (isRecipient) {
      setIsRecipientFilled(true)
      setAmountReceipt(getText)
      return
    }
    setIsRecipientFilled(false)
    setAmountSender(getText)
  }

  const debouncedSenderAmount = useDebounce(amountSender, 750)
  const debouncedReceiptAmount = useDebounce(amountReceipt, 750)

  useEffect(() => {
    changeRecipientValues()
  }, [debouncedSenderAmount])

  useEffect(() => {
    changeSenderValues()
  }, [debouncedReceiptAmount])

  const changeSenderValues = async () => {
    try {
      if (!isRecipientFilled) return
      const getAmount = currToNumber(amountReceipt)
      if (isSGISO)
        return changeSenderValuesSG({ ...allHooks, amountReceipt: getAmount })

      setAmountReceipt(formatCurrency(amountReceipt))
      const url = `/api/conversion_rates/get_rates?sender_country_id=${SafeIdRecipient}&receipt_country_id=95&amount=${getAmount}&user_id=1${isChinaQuery}`
      const { data } = await api.get(url)

      setSaving(thousands_separators(Math.round(data.total_save_up_receipt)))
      setAmountSender(
        numeral(Math.round(data.total_amount_receipt)).format("0,0")
      )
      setToggle(2)
    } catch (error) {
      console.log("error1:", error)
    }
  }

  const changeRecipientValues = async () => {
    try {
      if (isRecipientFilled) return
      const getAmountSender = currToNumber(amountSender)
      if (isSGISO)
        return changeRecipientValuesSG({
          ...allHooks,
          amountSender: getAmountSender,
        })

      setAmountSender(numeral(amountSender).format("0,0"))
      const getAmount = currToNumber(amountSender)
      const url = `/api/conversion_rates/get_rates?sender_country_id=${sender.id}&receipt_country_id=${SafeIdRecipient}&amount=${getAmount}&user_id=1${isChinaQuery}`
      const { data } = await api.get(url)

      const {
        total_save_up,
        fee,
        rate,
        limit_transaction,
        time_description,
        amount_receipt,
      } = data
      setSaving(thousands_separators(Math.round(total_save_up)))
      setCost(thousands_separators(fee))
      setKurs(thousands_separators(rate.kurs))
      setLimitTransaction(thousands_separators(limit_transaction))
      // setEstimate(time_description)
      setAmountReceipt(formatCurrency(amount_receipt))
      setToggle(1)
    } catch (error) {
      console.log("error2:", error)
    }
  }
  // calls these functions when user types on calculator

  const safeKursRate = isSGISO ? defaultCurr : SafeRecipientCurrency
  const slaValue = isSGISO
    ? isEnglish
      ? sgSLA.en
      : sgSLA.id
    : isEnglish
    ? SafeRecipientEn
    : SafeRecipientId
  const MaximumTransaction = `${SafeRecipientCurrency} ${limitTransaction}`
  const TotalPayment = `${defaultCurr} ${amountSender}`
  const slaTitle = isSGISO
    ? isEnglish
      ? `Your money will arrive`
      : "Uang Anda akan tiba pada"
    : title7

  const SlaSg = isEnglish
    ? `Your money will arrive`
    : `Uang Anda akan tiba pada`
  const SlaSg1title = isEnglish ? `Realtime ` : `Saat ini juga `
  const SlaSg1desc = isEnglish
    ? `if send below IDR 25,000,000`
    : `jika mengirim dibawah IDR 25,000,000`
  const SlaSg2title = isEnglish ? `D+1 banking day ` : `H+1 hari kerja `
  const SlaSg2desc = isEnglish
    ? `if send above IDR 25,000,000`
    : `jika mengirim diatas IDR 25,000,000`

  return (
    <>
      <CalculatorDiv style={style} className={className}>
        <form style={{ marginBottom: "0" }}>
          <div className="calculator-body" style={{ flex: "1" }}>
            <CurrencyInputWrapper>
              <CurrencyInputLabel>{title1}</CurrencyInputLabel>
              <CurrencyInputRow>
                <DropDownWrapper currency={sender.currency} iso={sender.iso}>
                  <CustomDropdownMenu>
                    {sourceCountries.map(detail => {
                      const { id, iso, iso_3, currency, name } = detail
                      const isoFlag = iso.toLowerCase() || 20
                      return (
                        <CustomDropdownItem
                          className="dropdown-list"
                          key={id}
                          eventKey={iso_3}
                          onSelect={() => handleChangeCountry(iso_3, id, false)}
                        >
                          <CountryFlag
                            src={`https://flagcdn.com/h80/${noCase(
                              isoFlag || ""
                            )}.png`}
                            alt="country-calculator"
                          />
                          {currency} {name}
                        </CustomDropdownItem>
                      )
                    })}
                  </CustomDropdownMenu>
                </DropDownWrapper>
                <SendInputGroup>
                  <CurrencyAmount
                    type="text"
                    id="amount-sender"
                    name="amount"
                    placeholder="0.00"
                    size="8"
                    value={amountSender}
                    onChange={onChange}
                    className="amount-sender"
                  />
                </SendInputGroup>
              </CurrencyInputRow>
            </CurrencyInputWrapper>
            {/* <DetailsDiv>
              <DetailsRow>
                <DetailsImg src={iconMinus} alt="icon-minus" />
                <DetailsSpan>{title3}</DetailsSpan>
                <DetailSpanValue>
                  {cost} {defaultCurr}
                </DetailSpanValue>
              </DetailsRow>
              <DetailsRow>
                <DetailsImg src={iconDevide} alt="icon-devide" />
                <DetailsSpan>{title4}</DetailsSpan>
                <DetailSpanValue>
                  1 {safeKursRate} = {kurs} IDR
                </DetailSpanValue>
              </DetailsRow>
            </DetailsDiv> */}
            <DetailsText>
              1 {safeKursRate} = {kurs} IDR | Fee {defaultCurr} {cost}
            </DetailsText>
            <CurrencyInputWrapper>
              <CurrencyInputLabel>{title2}</CurrencyInputLabel>
              <CurrencyInputRow>
                <DropDownWrapper
                  currency={SafeRecipientCurrency}
                  iso={defaultFlag}
                >
                  <CustomDropdownMenu>
                    <SearchBox type="text" onChange={handleSearch} />
                    {displayedContacts.map(detail => {
                      const {
                        id,
                        iso,
                        iso_3,
                        currency,
                        name,
                        iso_name,
                      } = detail
                      const isoFlag = iso.toLowerCase() || 20

                      const LabelCountry = () => {
                        if (iso_name === "VIET NAM") return "VIETNAM"
                        if (iso_name === "TAIWAN, PROVINCE OF CHINA")
                          return "TAIWAN"

                        return iso_name || name
                      }
                      const isoName = LabelCountry()

                      return (
                        <CustomDropdownItem
                          className="dropdown-list"
                          key={id}
                          eventKey={iso_3}
                          onSelect={() => handleChangeCountry(iso_3, id, true)}
                        >
                          <CountryFlag
                            src={`https://flagcdn.com/h80/${noCase(
                              isoFlag || ""
                            )}.png`}
                            alt="country-calculator"
                          />
                          {currency} {isoName}
                        </CustomDropdownItem>
                      )
                    })}
                  </CustomDropdownMenu>
                </DropDownWrapper>
                <SendInputGroup>
                  <CurrencyAmount
                    type="text"
                    id="amount-receipt"
                    name="amount"
                    placeholder="0.00"
                    size="8"
                    value={amountReceipt}
                    onChange={e => onChange({ ...e, isRecipient: true })}
                  />
                </SendInputGroup>
              </CurrencyInputRow>
            </CurrencyInputWrapper>
            <SellingPointList>
              <li>{sellingPoint.point1}</li>
              <li>
                {rrs(sellingPoint.point2, "{saved}", _ => (
                  <strong>
                    {defaultCurr} {saving}
                  </strong>
                ))}
              </li>
              <li>
                {rrs(sellingPoint.point3, "{maxCurrency}", _ => (
                  <strong>{MaximumTransaction}</strong>
                ))}
              </li>
              <li>
                {rrs(sellingPoint.point4, "{today}", _ => (
                  <strong>{SafeRecipientSLA}</strong>
                ))}
                {/* <OutlineButton
                  className="rounded-pill d-inline ml-2"
                  type="button"
                >
                  Detail
                </OutlineButton> */}
              </li>
            </SellingPointList>
            <TotalPaymentDiv>
              <p>{isEnglish ? "Total Payment" : "Total Pembayaran"}</p>
              <h6>{TotalPayment}</h6>
            </TotalPaymentDiv>

            {/* <SavingValueDiv>
              <SavingValueText>
                <SavingValueP>
                  {title5}
                  <SavingValueSpan>
                    {" "}
                    {saving} {defaultCurr}
                  </SavingValueSpan>
                </SavingValueP>
                <SavingValueSmall>{title6}</SavingValueSmall>
              </SavingValueText>
            </SavingValueDiv> */}
            {/* {isSGISO ? (
              <>
                <WillAriveP mbottom="0">{SlaSg}</WillAriveP>
                <WillAriveP mbottom="0">
                  <WillAriveSpan>
                    <b>{SlaSg1title}</b>
                  </WillAriveSpan>
                  {SlaSg1desc}
                </WillAriveP>
                <WillAriveP>
                  <WillAriveSpan>
                    {" "}
                    <b>{SlaSg2title}</b>
                  </WillAriveSpan>
                  {SlaSg2desc}
                </WillAriveP>
              </>
            ) : (
              <>
                <WillAriveP>
                  {slaTitle}
                  <WillAriveSpan> {slaValue}</WillAriveSpan>
                </WillAriveP>
              </>
            )} */}
            <Link
              to={propsLink.Download || ""}
              className="text-decoration-none"
            >
              <SendButton className="shadow">
                <img src={SendIcon} alt="Send" className="mr-2" />
                {title8}
              </SendButton>
            </Link>
          </div>
        </form>
      </CalculatorDiv>
    </>
  )
}

export default Calculator
