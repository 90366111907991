import React from "react"
import Container from "react-bootstrap/Container"
import styled from "styled-components"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { SendMoneyPath } from "../location"
import bigGlobeCard from "../../images/advantages-big-globe.png"
import tagCard from "../../images/advantages-tag.png"
import walletCard from "../../images/advantages-wallet.png"
import clockCard from "../../images/advantages-clock.png"
import shieldCard from "../../images/advantages-shield.png"
import phoneCard from "../../images/advantages-phone.png"
import locationCard from "../../images/advantages-location.png"
import billCard from "../../images/advantages-bill.png"

import { CustomDot } from "../Reusable/dot-line"
import { IdComponent } from "../Reusable/id-components"
import { useLocation } from "@reach/router"

function FeatureSection({ props, propsData }) {
  const location = useLocation()
  const isSg = location.pathname.includes("/sg")

  const {
    Title,
    TitleSg,
    Point1,
    Desc1,
    Point2,
    Desc2,
    Point3,
    Desc3,
    Point4,
    Desc4,
    Point5,
    Desc5,
    Point6,
    Desc6,
    Point7,
    Desc7,
    Point1sg,
    Desc1sg,
    Point5sg,
    Desc5sg,
  } = props || {}

  const bigTitle = isSg ? TitleSg : Title
  const TitleDecider = isSg ? Point1sg : Point1
  const DescDecider = isSg ? Desc1sg : Desc1

  const dataRendering = [
    {
      title: Point2,
      desc: Desc2,
      background: "#5A489C",
      icon: walletCard,
    },
    {
      title: Point3,
      desc: Desc3,
      background: "#0B7C8C",
      icon: tagCard,
    },
    {
      title: Point4,
      desc: Desc4,
      background: "#C8A2C8",
      icon: shieldCard,
    },
    ...(isSg
      ? [
          {
            title: Point5sg,
            desc: Desc5sg,
            background: "#FCB036",
            icon: billCard,
          },
        ]
      : [
          {
            title: Point5,
            desc: Desc5,
            background: "#FCB036",
            icon: clockCard,
          },
        ]),
    {
      title: Point6,
      desc: Desc6,
      background: "#FEADAA",
      icon: locationCard,
      style: { fontSize: 18 },
    },
    {
      title: Point7,
      desc: Desc7,
      background: "#9AD7D0",
      icon: phoneCard,
      style: { fontSize: 18 },
    },
  ]

  return (
    <>
      <IdComponent title="feature" />
      <Container className="my-5">
        <FeatureTitle>{bigTitle}</FeatureTitle>
        <BoxDiv bcolor="#333334" margin992="0px">
          <Col lg={6}>
            <TextDiv>
              <MainTitle fsize={"36px"}>{TitleDecider}</MainTitle>
              <MainDesc>{DescDecider}</MainDesc>
            </TextDiv>
          </Col>
          <Col lg={6} style={{ position: "relative" }}>
            <ImgBigGlobe src={bigGlobeCard} alt="globe" />
          </Col>
        </BoxDiv>
        <Row>
          {dataRendering?.map((item, index) => {
            const { title, desc, background, icon } = item || {}
            const isEven = (index + 1) % 2 === 0
            const marginDecider = isEven
              ? "10px 65px 10px 10px"
              : "10px 10px 10px 65px"
            const isSgAndBill = isSg && index === 3
            return (
              <Col lg={6} key={index} style={{ padding: 0 }}>
                <BoxDiv
                  bcolor={background}
                  margin={marginDecider}
                  margin992="10px"
                >
                  <Col lg={7}>
                    <TextDiv width="100%">
                      <MainTitle
                        fsize={isSgAndBill ? "32px" : "32px"}
                        fsizeres={isSgAndBill && "24px"}
                      >
                        {title}
                      </MainTitle>
                      <MainDesc style={item.style}>{desc}</MainDesc>
                    </TextDiv>
                  </Col>
                  <Col lg={5} style={{ position: "relative" }}>
                    <ImgCustom
                      src={icon}
                      alt={title}
                      width={isSgAndBill && "200px"}
                    />
                  </Col>
                </BoxDiv>
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  )
}

export default FeatureSection

const FeatureTitle = styled.h2`
  font-family: "Typefez-extrabold";
  color: #102c57;
  font-size: 60px;
  text-align: center;
  margin-bottom: 70px;
  @media (max-width: 576px) {
    font-size: 52px;
  }
`

const BoxDiv = styled(Row)`
  position: relative;
  overflow: hidden;
  padding: 30px 15px;
  height: 450px;
  background-color: ${p => p.bcolor};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin: ${p => p.margin || "10px 50px"};
  @media (max-width: 992px) {
    margin: ${p => p.margin992};
  }
`

const TextDiv = styled.div`
  width: ${p => p.width || "70%"};
`

const MainTitle = styled.h4`
  font-size: ${p => p.fsize || "40px"};
  color: white;
  font-family: "Typefez-bold";
  margin-bottom: 70px;
  height: 80px;
  @media (max-width: 576px) {
    font-size: ${p => p.fsizeres || "32px"};
    margin-bottom: 48px;
    height: 110px;
  }
`

const MainDesc = styled.p`
  font-size: 20px;
  color: white;
  font-family: "Typefez-med";
  @media (max-width: 576px) {
    font-size: 16px;
  }
`

const ImgCustom = styled.img`
  position: absolute;
  bottom: -10px;
  right: -30px;
  min-width: ${p => p.width};
  @media (max-width: 992px) {
    width: 220px;
    bottom: -30px;
  }
  @media (max-width: 768px) {
    width: 150px;
    bottom: -30px;
  }
`

const ImgBigGlobe = styled.img`
  position: absolute;
  bottom: -70px;
  right: -95px;
  width: 700px;
  transform: scale(1.5);
  @media (max-width: 992px) {
    width: 350px;
    bottom: -40px;
  }
  @media (max-width: 768px) {
    width: 270px;
    bottom: -30px;
  }
  @media (max-width: 576px) {
    width: 220px;
    bottom: -40px;
  }
`
